
.marqueex {
  height: 55px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marqueex .marqueex-wrapper {
  display: block;
  width: 200%;
  height: 55px;
  position: absolute;
  overflow: hidden;
  animation: marqueex 5s linear infinite;
}

.marqueex:hover > .marqueex-wrapper {
    animation-play-state: paused !important;
}

@media screen and (max-width: 500px) {
    .marqueex .marqueex-wrapper {
        height: 42px;
    } 
  }

.marqueex .marqueex-children {
  float: left;
  display: inline-block;
  width: 50%;
  overflow: hidden;
}

@keyframes marqueex {
  0% { left: 0; }
  100% { left: -100%; }
}